import {
  DesignerApi,
  StepDefinition as CoreStepDefinition,
  UiComponent,
  DesignerExtension,
  Vector,
} from 'sequential-workflow-designer';
import { StepDefinition } from '../types/StepDefinition';
import { MouseEvent, TouchEvent } from 'react';

export class CustomToolboxExtension implements UiComponent {
  public static instance: CustomToolboxExtension = undefined!;

  constructor(public api: DesignerApi = {} as any) {
    CustomToolboxExtension.instance = this;
  }

  public static handleMouseDown(ev: MouseEvent, step: StepDefinition) {
    ev.preventDefault();
    CustomToolboxExtension.instance.tryDrag(ev.clientX, ev.clientY, {
      ...step,
    });
  }

  public static handleTouchStart(ev: TouchEvent, step: StepDefinition) {
    ev.preventDefault();
    CustomToolboxExtension.instance.tryDrag(ev.touches[0].clientX, ev.touches[0].clientY, {
      ...step,
    });
  }

  public tryDrag(x: number, y: number, step: CoreStepDefinition) {
    this.getApi().toolbox.tryDrag(new Vector(x, y), step);
  }

  public isReady() {
    return !!this.api;
  }

  public setApi(t: DesignerApi) {
    if (t && this.api) {
      throw new Error('Api is already set');
    }
    this.api = t;
  }

  public getApi() {
    if (!this.api) {
      throw new Error('Designer is not ready yet');
    }
    return this.api;
  }

  public destroy(): void {
    // throw new Error('Method not implemented.');
  }

  public static create(): DesignerExtension {
    return {
      uiComponents: [
        {
          create: (root: HTMLElement, api: DesignerApi): UiComponent => (new CustomToolboxExtension(api)),
        },
      ],
    };
  }
}
