import { STANDARD_EVALUATOR } from '@privacy-request/oql';
import {
  ErrorBoundary, Fields,
  Grid,
  Text,
} from '@privacy-request/ui';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExpandAndCollapse } from '../ExpandAndCollapse/ExpandAndCollapse';
import { Spacer } from '../Spacer/Spacer';
import { CustomField } from './CustomField';
import { CustomFieldsContext } from './CustomFields';
import { opts } from './utils/opts';
import { render } from './utils/render';

const Content = styled.div`
  width: 100%;
`;
Content.displayName = 'Content';

const CustomSectionContainer = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

export interface CustomSection {
  type: 'section'
  helpText?: string
  config: CustomField[]
  title?: string
  fluid?: boolean
  description?: React.ReactNode
  storageKey?: string
  expandable?: boolean
  show?: any
  hide?: boolean
  /**
   * Disable bottom spacer/hr by setting this to false. Defaults to `true`
   */
  spacer?: boolean
}

export const CustomSectionComponent = ({
  section,
  value,
  onChange,
  last,
  mask,
  errors,
  ctx,
}: {
  section: CustomSection
  value: any
  onChange: any
  last: boolean
  errors: any
  mask: any
  ctx?: CustomFieldsContext
}) => {
  const [t, i18n] = useTranslation();

  // getWidth
  const gw = useRef((el?: CustomField): number => {
    if (!el) {
      return 1;
    }

    if (el.type === 'fileupload') {
      return el.single ? (el.width || 1) : 2;
    }

    if (el.type === 'boolean' || el.type === 'checkover' || el.type === 'custom') {
      return el.width || 2;
    }

    return el.width || 1;
  });

  // This thing of beauty, with the help of Thomas, organizes the view
  // into widths of 2s total.
  // Example: [1, 1, 2, 1, 2, 1]
  // Becomes: [[1, 1], [2], [1], [2], [1]];
  const config = useMemo(() => {
    const input: CustomField[] = section.config
      .filter((el) => {
        if (!el) {
          return false;
        }
        if (el.show && !STANDARD_EVALUATOR(el.show, value)) {
          return el.displace !== false;
        }
        return el;
      });
    return input.reduce((result, i) => {
      const el = result.slice(-1)[0];
      if (el.length === 1 && gw.current(el[0]) < 2 && gw.current(i) < 2) {
        el.push(i);
      } else {
        result.push([i]);
      }
      return result;
    }, [[input.shift()]]);
  }, [section.config, value]);

  if (typeof section.show !== 'undefined' && !STANDARD_EVALUATOR(section.show, value)) {
    return null;
  }

  const content = (
    <>
      {section.description && <Text style={{ marginBottom: '16px' }}>{section.description}</Text>}
      {!!section.config.length && config.map(([first, second]: any, i: number) => {
        if (first && second) {
          return (
            <Fields key={i}>
              {render(first, opts(value, onChange, false, true, false, mask, errors, t, i18n, ctx))}
              {render(second, opts(value, onChange, true, false, false, mask, errors, t, i18n, ctx))}
            </Fields>
          );
        }

        if (first && !second && gw.current(first) === 2) {
          return (
            <Fields key={i}>
              {render(first, opts(value, onChange, true, true, false, mask, errors, t, i18n, ctx))}
            </Fields>
          );
        }

        if (first && !second && gw.current(first) === 1) {
          return (
            <Fields key={i}>
              {render(first, opts(value, onChange, false, true, false, mask, errors, t, i18n, ctx))}
              {render({ type: 'blank' }, opts(value, onChange, true, false, false, mask, errors, t, i18n, ctx))}
            </Fields>
          );
        }

        return <div key={i}>THIS SHOULDNT HAPPEN</div>;
      })}
    </>
  );

  if (!section.title || !section.storageKey) {
    return (
      <ErrorBoundary>
        <CustomSectionContainer>
          <Grid fluid={section.fluid}>
            <Content>
              {content}
            </Content>
          </Grid>
          {!last && section.spacer !== false && <Spacer />}
        </CustomSectionContainer>
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <CustomSectionContainer>
        <ExpandAndCollapse title={section.title} fluid={section.fluid} helpText={section.helpText} storageKey={section.storageKey} expandable={section.expandable}>
          {content}
        </ExpandAndCollapse>
        {!last && section.spacer !== false && <Spacer />}
      </CustomSectionContainer>
    </ErrorBoundary>
  );
};
