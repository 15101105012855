import { PipelineStep } from '@privacy-request/basic-types';
import {
  Field, Input, Label, PlusIcon,
} from '@privacy-request/ui';
import React, {
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomFields } from '../CustomFields/CustomFields';
import { DebugEditor } from '../DebugEditor/DebugEditor';
import { PipelineStepHeader, stepNameToTitle } from './PipelineStepHeader';
import { WorkflowDesigner } from '../WorkflowManager/WorkflowDesigner';

const Collapsible = styled.div`
  transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
  margin-bottom: 0;
  max-height: 1000000px;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.4);
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgb(209 219 203);

  &:after {
    height: 0;
    transition: height 0.3s linear;
    max-height: 50px;
  }
`;
const CollapsibleContainer = styled.div`
  display: flex;
  width: 100%;

  &:after {
    content: '';
    height: 50px;
    transition: height 0.3s linear,
                max-height 0s 0.3s linear;
    max-height: 0px;
  }

  &.collapsed,
  .collapsed & {
    overflow: hidden;
    &:after {
      height: 0;
      transition: height 0.3s linear;
      max-height: 50px;
    }
    & > ${Collapsible} {
      margin-bottom: -2000px;
      transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1),
                  visibility 0s 0.3s, max-height 0s 0.3s;
      visibility: hidden;
      max-height: 0;
    }
  }
`;

const Step = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  > ${CollapsibleContainer} > div {
    margin: 16px;
  }
`;

const Inserter = styled.button`
  // Reset
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: relative;
  max-width: 456px;

  // Style
  box-sizing: border-box;
  display: block;
  height: 28px;
  width: 100%;
`;

const InserterLine = styled.div`
  background: #ddd;
  border-radius: 10px;
  height: 5px;
  transition: background .25s ease-in-out;

  ${Inserter}:hover & {
    background: ${({ theme }) => (theme.CTA_COLOR)};
  }
`;

const InserterIcon = styled(PlusIcon)`
  background: #ddd;
  border-radius: 100px;
  padding: 3px;
  position: absolute;
  top: 2px;
  transition: background .25s ease-in-out;

  ${Inserter}:hover & {
    background: ${({ theme }) => (theme.CTA_COLOR)};
  }
`;

export const InsertButton = (props: any) => (
  <Inserter {...props}>
    <InserterLine />
    <InserterIcon width={18} height={18} />
  </Inserter>
);

export type PipelineBuilderStepProps = {
  step: PipelineStep
  integration: any
  onChange: Function
  onDelete: MouseEventHandler<SVGSVGElement>
};

export const PipelineBuilderStep = React.memo(({
  step,
  integration,
  onChange,
  onDelete,
}: PipelineBuilderStepProps) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();

  const form = integration?.forms?.[step.handler];

  const onChangeStep = useCallback((key: string, value: any) => {
    onChange({
      ...step,
      [key]: value,
    });
  }, [onChange, step]);
  const _onChange = useCallback((ev) => {
    const { target: { name, value } } = ev;
    const { config, ..._step } = step;
    onChange({
      ..._step,
      config: {
        ...config,
        [name]: value,
      },
    });
  }, [onChange, step]);
  const onNameChange = useCallback((e) => {
    onChange({
      ...step,
      name: e.target.value,
    });
  }, [onChange, step]);
  const _onDebugChange = useCallback(({ target: { value } }) => {
    onChange({
      ...step,
      config: value,
    });
  }, [step, onChange]);
  const onToggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Step className={open ? '' : 'collapsed'}>
      <PipelineStepHeader form={form} config={step.config} step={step} onClick={onToggleOpen} onChange={onChangeStep} onDelete={onDelete} open={open} integration={integration} />
      {form && (
        <CollapsibleContainer>
          <Collapsible>
            <div>
              <Field style={{ maxWidth: '456px' }}>
                <Label>
                  Name (
                  {step.handler}
                  )
                </Label>
                <Input
                  value={step.name || stepNameToTitle(t, step.handler)}
                  onChange={onNameChange}
                />
              </Field>
              {step.config ? (<CustomFields config={form} onChange={_onChange} value={step.config} />) : null}

              <DebugEditor value={step.config} onChange={_onDebugChange} />
            </div>
          </Collapsible>
        </CollapsibleContainer>
      )}
      {step.handler === 'runSequence' && (
        <CollapsibleContainer>
          <Collapsible>
            <div>
              <Field style={{ maxWidth: '456px' }}>
                <Label>
                  Name (
                  {step.handler}
                  )
                </Label>
                <Input
                  value={step.name || stepNameToTitle(t, step.handler)}
                  onChange={onNameChange}
                />
              </Field>
              <WorkflowDesigner
                steps={[]}
                workflow={{} as any}
                onChange={undefined!}
              />

              <DebugEditor value={step.config} onChange={_onDebugChange} />
            </div>
          </Collapsible>
        </CollapsibleContainer>
      )}
    </Step>
  );
});
