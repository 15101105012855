import React from 'react';
import { useStepEditor } from 'sequential-workflow-designer-react';
import { Step } from '../types/Step';
import { Definition } from 'sequential-workflow-designer';
import { I18nextProvider } from 'react-i18next';
import { CustomFields } from '../../CustomFields/CustomFields';
import i18next from '../../../translations/i18next';
import {
  Field,
  Fields,
  Input,
  Label,
} from '@privacy-request/ui';

export const StepEditor = () => {
  const {
    name,
    setName,
    properties,
    setProperty,
    step,
  } = useStepEditor<Step, Definition>();

  const onNameChanged = (e: any) => {
    setName(e.target.value);
  };

  const onPropertyChanged = ({ target: { name, value } }: any) => {
    setProperty(name, value);
  };

  return (
    <div className="step-editor">
      <Field fluid>
        <Label>Name</Label>
        <Input value={name} onChange={onNameChanged} />
      </Field>

      <Fields>
        <I18nextProvider i18n={i18next}>
          <CustomFields config={step.form} onChange={onPropertyChanged} value={properties} fluid />
        </I18nextProvider>
      </Fields>
    </div>
  );
};
