import {
  CaretDownIcon,
  ListRow,
  ListText,
} from '@privacy-request/ui';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { systemImageLocation } from '../../../SystemsView/utils/systemImageLocation';
import { IconWrapper } from '../../../../components/ExpandAndCollapse/ExpandAndCollapse';
import { useMe } from '../../../../hooks/useMe';
import { formatContact } from '../../../../utils/formatContact';
import { stripDeleted } from '../../../../utils/stripDeleted';
import { RequestTaskListItemDetails } from './RequestTaskListItemDetails';
import { Task } from '../../../../types/request/Task';
import { getRequestTaskStatus } from '@privacy-request/common/src/utils/requests/getRequestTaskStatus';
import { RequestStatus } from '@privacy-request/value-objects';
import { TaskRetryButton } from '../../../../components/TaskRetry/TaskRetryButton';

export interface RequestTaskListItemProps {
  task: Partial<Task>
  disabled?: boolean
  request_type?: string
  request_status: RequestStatus
  expanded?: boolean
  primary?: boolean
  isVendor?: boolean
}

const styles = {
  row: {
    display: 'block',
    padding: 0,
    height: 'unset',
  },
};

export const RequestTaskListItem = ({
  task, disabled, request_type, request_status, primary, expanded: forceExpanded, isVendor,
}: RequestTaskListItemProps) => {
  const [expanded, setExpanded] = useState(forceExpanded || false);
  const [t] = useTranslation('requests');
  const { me } = useMe();

  const god_mode = me?.god_mode;

  const { completed_at } = task;

  useEffect(() => {
    if (completed_at) {
      setExpanded(forceExpanded || false);
    }
  }, [completed_at, forceExpanded]);

  const [status, color, bold] = useMemo(() => getRequestTaskStatus(request_type || 'download', request_status, task, t), [request_type, request_status, task, t]);

  const assignee = (task.hasPipelines || task.hasSequences) ? t('task.system') : task.system?.contacts?.map(formatContact).join(', ');

  return (
    <ListRow secondary={!primary} style={styles.row}>
      <ListRow secondary onClick={(disabled || forceExpanded) ? undefined : () => setExpanded(s => !s)} style={{ marginTop: 0 }} plain>
        <ListText style={{ display: 'flex', alignItems: 'center' }} basis="32px">
          <img
            alt="Asset Logo"
            style={{ width: '32px', height: '32px' }}
            src={systemImageLocation(task.system)}
          />
        </ListText>
        <ListText bold>{stripDeleted(task.system?.name || '')}</ListText>
        <ListText
          bold={bold}
          style={{
            color, display: 'flex', alignItems: 'center',
          }}
        >
          {status}
          {task.system_integration_name && (god_mode || (me?.organization.features.enable_task_retry_button && me.role.code === 'admin')) && (
            <TaskRetryButton
              integrationVersion={task.integration_version!}
              taskId={task.id!}
              requestId={task.request_id!}
              systemId={(task.system!.id)!}
            />
          )}
        </ListText>
        <ListText grow={2}>
          {assignee}
        </ListText>
        <ListText basis="32px">
          {!disabled && !forceExpanded && (
            <IconWrapper expanded={expanded}>
              <CaretDownIcon width={32} height={32} color="rgba(0,0,0,0.8)" />
            </IconWrapper>
          )}
        </ListText>
      </ListRow>
      {expanded && (
        <RequestTaskListItemDetails task={task} request_status={request_status} isVendor={isVendor} />
      )}
    </ListRow>
  );
};
