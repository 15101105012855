import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';
import { MsalProvider } from '@azure/msal-react';
import { defaultTheme } from '@privacy-request/ui';
import { client } from './apollo';
import App from './App';
import { pca } from './config/msal';
import { SUBDOMAIN } from './constants';
import * as serviceWorker from './serviceWorker';
import store from './store';
import i18next from './translations/i18next';
import { HotkeyProvider } from './components/HotkeyProvider/HotkeyProvider';
import { GlobalDirtySaveRevert } from './components/GlobalDirtySaveRevert/GlobalDirtySaveRevert';
import { ModalProvider } from './hooks/useModalContext';

// @ts-expect-error
ReactDOM.createRoot = (container: any) => ({
  render: (el: any) => {
    ReactDOM.render(el, container);
  },
  unmount: () => {},
});

const theme = {
  ...defaultTheme,
  BTN_HOVER: '#164864',
};

const build = () => {
  const result = (
    <I18nextProvider i18n={i18next}>
      <Router>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <ApolloProvider client={client}>
              <ToastProvider placement="top-center">
                <ModalProvider>
                  <HotkeyProvider>
                    <GlobalDirtySaveRevert>
                      <App />
                    </GlobalDirtySaveRevert>
                  </HotkeyProvider>
                </ModalProvider>
              </ToastProvider>
            </ApolloProvider>
          </Provider>
        </ThemeProvider>
      </Router>
    </I18nextProvider>
  );

  if (SUBDOMAIN) {
    return (
      <MsalProvider instance={pca}>
        {result}
      </MsalProvider>
    );
  }
  return result;
};

ReactDOM.render(
  build(),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
