import React from 'react';
import { useRootEditor } from 'sequential-workflow-designer-react';

export const RootEditor = () => {
  const {
    properties,
    setProperty,
  } = useRootEditor();

  function onSpeedChanged(e: any) {
    setProperty('speed', e.target.value);
  }

  return (
    <>
      <h3>Speed</h3>
      <input value={String(properties.speed ?? '')} onChange={onSpeedChanged} />
    </>
  );
};
