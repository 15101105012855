import React, {
  MouseEvent,
  TouchEvent,
  useMemo,
} from 'react';
import { StepDefinition } from '../types/StepDefinition';

export type CustomToolboxProps = {
  steps: StepDefinition[]
  handleMouseDown: (ev: MouseEvent, step: StepDefinition) => void
  handleTouchStart: (ev: TouchEvent, step: StepDefinition) => void
};

export const CustomToolbox = ({
  steps,
  handleMouseDown,
  handleTouchStart,
}: CustomToolboxProps) => {
  const groups = useMemo(() => (
    steps.reduce((result, step) => {
      const groups = step.groups ?? ['ungrouped'];
      groups.forEach((group) => {
        result[group] = (result[group] ?? []);
        result[group].push(step);
      });
      return result;
    }, {} as any)
  ), [steps]);

  return (
    <div className="custom-toolbox">
      <h3>Toolbox</h3>
      {
        Object.keys(groups).map((group) => (
          <details key={group}>
            <summary>
              {group}
              &nbsp;
              (
              {groups[group].length}
              )
            </summary>
            <ul className="tree">
              {
                groups[group].map((step: any) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-redundant-roles
                  <li
                    key={step.type}
                    className="custom-toolbox-item"
                    onMouseDown={(ev: MouseEvent) => (handleMouseDown(ev, step))}
                    onTouchStart={(ev: TouchEvent) => (handleTouchStart(ev, step))}
                    role="listitem"
                  >
                    {step.name}
                  </li>
                ))
              }
            </ul>
          </details>
        ))
      }
    </div>
  );
};
