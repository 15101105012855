import React from 'react';
import { STANDARD_EVALUATOR } from '@privacy-request/oql';
import { Field } from '@privacy-request/ui';
import { CustomField } from '../CustomField';
import { CustomFieldCommonOptions } from '../CustomFieldCommonOptions';
import { renderField } from './renderField';
import { renderWrapper } from './renderWrapper';

export const render = (config: CustomField, opts: CustomFieldCommonOptions) => {
  if (typeof config.show !== 'undefined' && !STANDARD_EVALUATOR(config.show, opts.values)) {
    return null;
  }

  if (!config) {
    return (<Field last={opts.last} first={opts.first} />);
  }

  const content = renderField(config, opts);
  return renderWrapper(config, { ...opts, content });
};
